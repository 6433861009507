.navbar-div-top{
    background-color: #064635; 
    color: #ffffff;   
}

.JJAE-navbar-bg{
    font-family: 'Roboto', sans-serif;
    background-color: #093b2f!important;
}

.navbar-div-top1{
    background-color: #AE8625;
    height: 5px;
}

.navbar-div-top2{
    background-color: #064635;
    color: rgb(255, 255, 255);  
}

.navbarmenu{
    background-color: #519259;
    color:#E8E8CC

}


.font-sg{
     font-family: 'Raleway', sans-serif;  
    font-family: 'Space Grotesk'!important;
    font-size: medium;
    letter-spacing: 0.5px;


}
.font-header{
    font-family: 'Roboto', sans-serif!important;

}
.navbar-brand {
    color: #ffffff!important;
    font-size: 1rem;
}

.navbar-div-language-selection{
    background-color: #6c757d!important;
    color: #ffffff!important;
    /* font-family: sans-serif; */
}
.navbar-div-content{
    background-color: #0767a7!important;  
    color: #ffffff!important;
    /* font-family: sans-serif!important; */


}

 .navbar-brand {
    color: #ffffff!important;
    font-size: 1rem;
}

.navbar-light .navbar-toggler {
    color: #ffffff!important;
}


 /* .nav-link  {
    color: #ffffff!important;
    
} */

.menu-active{
    font-weight: bold !important;
    /* border-bottom: 1p solid !important;
    border-bottom-color: red !important; */
}

.navbar-font{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif, 'Catamaran' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  
.menuactive {
    font-weight: bold !important;
}

.menuInactive {
    font-weight: normal!important;
}
