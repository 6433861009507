.processContent{
    font-size: low;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif!important;
    color:000000;
   
}


.processContentTitle{
    font-size: 1.00rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
    text-align: start;
    color: rgb(255, 72, 16)!important;
   
}