.homeAboutHeader{
    font-size: xx-large;
    font-weight: bolder;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif!important;
    color:rgb(45, 73, 105); 
}

.homeAboutHeadersub{
    font-size:large;
    font-weight: bolder;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif!important;
    color:rgb(45, 73, 105); 

}

.homeAboutContent{
    font-size: medium;
    font-weight:700;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif!important;
    color: rgb(51, 114, 109) ;
   
}


.homeAboutIntroContentTitle{
    font-size: 1.75rem;
    line-height: 2.5rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
    text-align: start;
    color: rgb(255, 72, 16)!important;
   
}

.homeClientIntroContentTitle{
    font-size: 2.82rem;
    line-height: 2.5rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
    text-align: start;
    
    
   
}

.homeAboutIntroContent{
    font-size: 1.125rem;
    line-height: 1.7rem;
    letter-spacing: .5px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    text-align: start;
}


.homeClientsHeaderDiv{
    background-color: #AE8625;
    height: 5px;
    margin-right: 3px;
}

/* .homeClientsHeaderDiv{
    background-color: #064635;
    color: rgb(255, 255, 255);
} */

.homeClientsHeaderDivTop{
    background-color: #064635;
    color: #ffffff;
    /* background-color: #f1f7f5; */
}

.homeleftDiv{
        width: 20px;


        /* padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto; */
}

.card {
    width: 21rem !important;
    margin: 10px;
}
.cardFontFamily{
        font-size: 0.9rem;
        line-height: 1.2rem;
        letter-spacing: .25px;
        color: #000;
        font-family: 'Poppins', sans-serif;
        text-align: start;
}

.cardTitleFontFamily{
    font-size: 1.25rem;
    font-weight: 400;
    /* line-height: 1.2rem; */
    letter-spacing: .25px;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    /* text-align: start; */
}